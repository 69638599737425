// store images when same root
class ImageSlidesOneEl {
  constructor(root, id) {
    this.root = root;
    this.id = id;

    this.btn = root.querySelector(".nordic-slideBtn");
    this.isNordicSpecial = false;
    if (this.btn !== null) {
      this.isNordicSpecial = true;
      this.images = root.querySelectorAll(".img-el");
    } else {
      this.images = root.querySelectorAll("img");
    }
    this.current = 0;
    this.count = this.images.length;
    this.currentLink = "";
    this.init();
  }
  init() {
    if (this.images.length >= 1) {
      this.currentLink = this.images[0].getAttribute("link_to");
    }
  }
  nextImage() {
    if (this.current + 1 >= this.images.length) {
      this.current = 0;
    } else {
      this.current += 1;
    }
    this.setNewLink();

    return this.getSrc();
  }

  setNewLink() {
    if (this.isNordicSpecial) {
      this.currentLink = this.images[this.current].getAttribute("link_to");
    }
  }
  prevImage() {
    if (this.current - 1 < 0) {
      this.current = this.images.length - 1;
    } else {
      this.current -= 1;
    }
    this.setNewLink();
    return this.getSrc();
  }
  getCount() {
    return this.count;
  }
  getLink() {
    if (this.isNordicSpecial) {
      if (this.currentLink !== null) {
        window.location.href = this.currentLink;
      }
    }
  }
  isNordic() {
    return this.isNordicSpecial;
  }
  getBtn() {
    return this.btn;
  }

  getSrc() {
    if (this.isNordicSpecial) {
      return this.images[this.current].getAttribute("data-src");
    } else {
      return this.images[this.current].src;
    }
  }
}
// takes multiple roots and make for each root a lightbox slideshow
export default class ImageSlidesPopUp {
  constructor(id = "") {
    if (id === "") {
      this.imageSlidesPopUpEls = document.querySelectorAll(".ImageSlidesPopUp");
      if (this.imageSlidesPopUpEls === null) return;
      if (this.imageSlidesPopUpEls.length === 0) {
        return;
      }
      this.imageSlides = [];
      this.imageSlidesPopUpEls.forEach((value, index) => {
        const one = new ImageSlidesOneEl(value, index);
        this.imageSlides.push(one);
      });
    } else {
      this.imageSlidesPopUpOneEl = document.querySelector(id);
      if (this.imageSlidesPopUpOneEl === null) return;
      this.imageSlides = [new ImageSlidesOneEl(this.imageSlidesPopUpOneEl, 0)];
    }
    this.id = id;
    this.current = 0;
    this.lightboxEl = this.createLightBoxEl();
    this.closeBtn = this.lightboxEl.querySelector(".close");
    this.nextBtn = this.lightboxEl.querySelector(".next");
    this.prevBtn = this.lightboxEl.querySelector(".prev");
    this.currentImg = this.lightboxEl.querySelector("img");

    this.setEventListenerPopUp();
    this.lightboxEvents();
    this.keydownEvents();
  }

  createLightBoxEl() {
    if (document.querySelector("#lightbox") !== null) {
      return document.querySelector("#lightbox");
    }
    const lightbox = document.createElement("div");
    lightbox.id = "lightbox";

    lightbox.innerHTML = `
      <img src="" alt="Bilder in Groß"/>
     <div class="close">
       <i class="far fa-times-circle"></i>
     </div>
     <div class="prev">
       <i class="fas fa-arrow-left"></i>
     </div>
     <div class="next">
       <i class="fas fa-arrow-right"></i>
     </div>
`;

    document.body.appendChild(lightbox);
    return document.querySelector("#lightbox");
  }

  popUpImage(activeImageSlide, image) {
    this.current = activeImageSlide.id;
    this.lightboxEl.classList.add("active");
    if (activeImageSlide.isNordic() === true) {
      this.currentImg.src = image.getAttribute("data-src");
    } else {
      this.currentImg.src = image.src;
    }

    activeImageSlide.images.forEach((value, index) => {
      if (activeImageSlide.isNordic() === true) {
        if (value.getAttribute("data-src") === image.src) {
          activeImageSlide.current = index;
          return;
        }
      } else {
        if (value.src === image.src) {
          activeImageSlide.current = index;
          return;
        }
      }
    });
  }

  setEventListenerPopUp() {
    this.imageSlides.forEach((each) => {
      if (each.isNordic() === true) {
        each.getBtn().addEventListener("click", (e) => {
          this.popUpImage(each, each.images[0]);
          this.lightboxEl.classList.add("nordic-special-img");
        });
      } else {
        each.images.forEach((image) => {
          image.addEventListener("click", (e) => {
            this.popUpImage(each, image);
            this.lightboxEl.classList.remove("nordic-special-img");
          });
        });
      }
    });
  }

  lightboxEvents() {
    this.closeBtn.addEventListener("click", () =>
      this.lightboxEl.classList.remove("active")
    );
    this.nextBtn.addEventListener(
      "click",
      () => (this.currentImg.src = this.imageSlides[this.current].nextImage())
    );
    this.prevBtn.addEventListener(
      "click",
      () => (this.currentImg.src = this.imageSlides[this.current].prevImage())
    );

    this.currentImg.addEventListener("click", () => {
      this.imageSlides[this.current].getLink();
    });

    this.lightboxEl.addEventListener("click", (e) => {
      if (e.target === this.lightboxEl) {
        this.lightboxEl.classList.remove("active");
      }
    });
  }

  keydownEvents() {
    document.addEventListener("keydown", (e) => {
      if (!this.lightboxEl.classList.contains("active")) {
        return;
      }
      if (e.keyCode == "39") {
        this.currentImg.src = this.imageSlides[this.current].nextImage();
      }
      if (e.keyCode == "37") {
        this.currentImg.src = this.imageSlides[this.current].prevImage();
      }
      if (e.keyCode == "27") {
        this.lightboxEl.classList.remove("active");
      }
    });
  }
}
