import Standard from "../../js/Libraries/Standard";
import jump from "jump.js";

/* 
  -fehler bei textupload in backend, was angezeigt???
  - 'data feld muss ausgefüllt sein, bisher ohne benarchitgn...
  - textarea text richtig abstimmen....
*/
export default class Back2Top extends Standard {
  constructor() {
    super("#Back2Top");
    if (!super.exist()) return;
    this.btn = super.set(".back_btn");
    this.events();
  }

  events() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 600) {
        this.btn.style.display = "flex";
      } else {
        this.btn.style.display = "none";
      }
    });

    this.btn.addEventListener("click", () => {
      jump("body", {
        duration: (distance) => Math.min(Math.abs(distance) / 2, 1500),
      });
    });
  }
}
